// src/components/Carousel.tsx
import React, { useState, useEffect } from 'react';
import { CarouselSlide } from '../types';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface CarouselProps {
  slides?: CarouselSlide[] | null;
}

export const Carousel: React.FC<CarouselProps> = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    if (Array.isArray(slides) && slides.length > 0) {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }
  };

  const prevSlide = () => {
    if (Array.isArray(slides) && slides.length > 0) {
      setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    }
  };

  useEffect(() => {
    if (Array.isArray(slides) && slides.length > 1) {
      const timer = setInterval(() => {
        nextSlide();
      }, 5000);

      return () => clearInterval(timer);
    }
  }, [slides]);

  if (!Array.isArray(slides) || slides.length === 0) {
    return (
      <div className="relative w-full h-128 bg-neutral-100 flex items-center justify-center">
        <p className="text-neutral-500 font-sans">No slides available</p>
      </div>
    );
  }

  return (
    <div className="relative w-full h-128">
      {/* Slides */}
      <div className="relative w-full h-full overflow-hidden">
        {slides.map((slide, index) => (
          <a
            key={slide.id}
            href={slide.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`absolute inset-0 transition-transform duration-700 ${
              index === currentSlide 
                ? 'opacity-100 translate-x-0 z-20' 
                : index < currentSlide 
                  ? 'opacity-0 -translate-x-full z-10'
                  : 'opacity-0 translate-x-full z-10'
            }`}
          >
            <div className="relative w-full h-full">
              <img
                src={slide.image}
                alt={slide.title}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-neutral-900/60 to-transparent" />
              <div className="absolute bottom-0 left-0 right-0 p-8">
                <h2 className="text-neutral-50 text-4xl font-bold mb-2 font-display">
                  {slide.title}
                </h2>
                <p className="text-neutral-200 text-lg max-w-xl font-sans">
                  {slide.description}
                </p>
              </div>
            </div>
          </a>
        ))}
      </div>

      {/* Navigation Arrows */}
      {slides.length > 1 && (
        <>
          <button
            onClick={prevSlide}
            className="absolute left-4 top-1/2 -translate-y-1/2 z-30 bg-neutral-50/90 p-3 rounded-full hover:bg-accent transition-colors group"
            aria-label="Previous slide"
          >
            <ChevronLeft className="w-6 h-6 text-neutral-900 group-hover:text-primary" />
          </button>
          <button
            onClick={nextSlide}
            className="absolute right-4 top-1/2 -translate-y-1/2 z-30 bg-neutral-50/90 p-3 rounded-full hover:bg-accent transition-colors group"
            aria-label="Next slide"
          >
            <ChevronRight className="w-6 h-6 text-neutral-900 group-hover:text-primary" />
          </button>
        </>
      )}

      {/* Slide Indicators */}
      <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex space-x-2 z-30">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentSlide(index)}
            className={`h-2 rounded-full transition-all duration-300 ${
              index === currentSlide 
                ? 'w-8 bg-accent' 
                : 'w-2 bg-neutral-50/50 hover:bg-neutral-50/70'
            }`}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};
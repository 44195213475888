import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ProductGrid } from '../components/ProductGrid';
import { FilterSidebar } from '../components/FilterSidebar';
import { Product, Filters, FilterOptions } from '../types';
import { FunnelIcon, XMarkIcon } from '@heroicons/react/24/outline';

export const CategoryPage: React.FC = () => {
  const { category } = useParams<{ category: string }>();
  const [products, setProducts] = useState<Product[]>([]);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    sizes: [],
    colors: [],
    brands: [],
    price_range: { min_price: 0, max_price: 0 }
  });
  const [filters, setFilters] = useState<Filters>({
    minPrice: '',
    maxPrice: '',
    sizes: [],
    colors: [],
    brands: []
  });
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [activeFiltersCount, setActiveFiltersCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [nextUrl, setNextUrl] = useState<string | null>(null);
  
  const loader = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const mounted = useRef(false);
  
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchFilterOptions = async () => {
      if (!category) return;

      try {
        const response = await fetch(`/api/filter-options/options/?category=${category}`);
        if (!response.ok) throw new Error('Failed to fetch filter options');

        const data = await response.json();
        if (!mounted.current) return;
        
        const sortedOptions: FilterOptions = {
          ...data,
          brands: [...data.brands].sort((a, b) => a.localeCompare(b)),
        };

        setFilterOptions(sortedOptions);
        setFilters(prev => ({
          ...prev,
          minPrice: data.price_range.min_price.toString(),
          maxPrice: data.price_range.max_price.toString()
        }));
      } catch (error) {
        console.error('Error fetching filter options:', error);
        setError('Failed to load filter options. Please try again later.');
      }
    };

    fetchFilterOptions();
  }, [category]);

  const loadProducts = useCallback(async (resetProducts = false) => {
  if (!category || (isLoading && !resetProducts) || !mounted.current) return;

  try {
    setIsLoading(true);
    setError(null);

    let url: string;
    if (resetProducts) {
      const queryParams = new URLSearchParams();
      
      if (filters.minPrice) queryParams.append('min_price', filters.minPrice);
      if (filters.maxPrice) queryParams.append('max_price', filters.maxPrice);
      if (filters.sizes.length) queryParams.append('sizes', filters.sizes.join(','));
      if (filters.colors.length) queryParams.append('colors', filters.colors.join(','));
      
      // Properly encode brand names
      if (filters.brands.length) {
        queryParams.append('brands', filters.brands.map(brand => 
          encodeURIComponent(brand.trim())
        ).join(','));
      }

      url = `/api/categories/${category}/products/?${queryParams}`;
      console.log('Request URL:', url); // Debug log
    } else {
      if (!nextUrl) {
        setHasMore(false);
        setIsLoading(false);
        return;
      }
     url = nextUrl.replace(/^http:/, 'https:');
}


    const response = await fetch(url);
    if (!response.ok) throw new Error('Failed to fetch products');

    const data = await response.json();
    // Debug log
    console.log('Response data:', data);

    if (!mounted.current) return;

    const newProducts = data.results || [];
    
    setProducts(prev => {
      if (resetProducts) {
        return newProducts;
      }
      const existingIds = new Set(prev.map(p => p.id));
      const uniqueNewProducts = newProducts.filter((p: any) => !existingIds.has(p.id));
      return [...prev, ...uniqueNewProducts];
    });

    setNextUrl(data.next);
    setHasMore(!!data.next && newProducts.length > 0);
  } catch (error) {
    console.error('Error loading products:', error);
    setError('Failed to load products. Please try again later.');
    setHasMore(false);
  } finally {
    setIsLoading(false);
  }
}, [category, filters, nextUrl]);

  useEffect(() => {
    if (!mounted.current) return;
    
    setNextUrl(null);
    setHasMore(true);
    setProducts([]);
    loadProducts(true);
  }, [category, filters]);

  useEffect(() => {
    if (isFilterOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isFilterOpen]);

  useEffect(() => {
    if (!hasMore || !loader.current || !mounted.current) {
      if (observerRef.current && loader.current) {
        observerRef.current.unobserve(loader.current);
        observerRef.current = null;
      }
      return;
    }

    observerRef.current = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && hasMore && !isLoading && mounted.current) {
          loadProducts(false);
        }
      },
      { 
        root: null,
        rootMargin: '100px',
        threshold: 0.1 
      }
    );

    observerRef.current.observe(loader.current);

    return () => {
      if (observerRef.current && loader.current) {
        observerRef.current.unobserve(loader.current);
      }
    };
  }, [hasMore, isLoading, loadProducts]);

  useEffect(() => {
    let count = 0;
    if (filters.minPrice !== filterOptions.price_range.min_price.toString()) count++;
    if (filters.maxPrice !== filterOptions.price_range.max_price.toString()) count++;
    count += filters.sizes.length;
    count += filters.colors.length;
    count += filters.brands.length;
    setActiveFiltersCount(count);
  }, [filters, filterOptions]);

  const handleFiltersChange = (newFilters: Filters) => {
    setHasMore(true);
    setProducts([]);
    setFilters(newFilters);
    if (isFilterOpen) {
      setIsFilterOpen(false);
    }
  };

  if (!category) {
    return <div className="text-center py-8">Category not found</div>;
  }

  return (
    <div className="min-h-screen bg-white">
      {/* Mobile filter dialog */}
      <div
        className={`fixed inset-0 z-40 lg:hidden ${isFilterOpen ? '' : 'pointer-events-none'}`}
      >
        {/* Overlay */}
        <div
          className={`fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ${
            isFilterOpen ? 'opacity-100' : 'opacity-0'
          }`}
          onClick={() => setIsFilterOpen(false)}
        />

        {/* Sidebar container */}
        <div
          className={`fixed inset-y-0 right-0 flex max-w-full transform transition-transform duration-300 ${
            isFilterOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          <div className="w-screen max-w-md h-full">
            <div className="flex flex-col h-full bg-white shadow-xl">
              {/* Header - Fixed */}
              <div className="sticky top-0 z-10 flex items-center justify-between px-4 py-4 border-b border-neutral-200 bg-white" style={{ marginTop: '150px' }}>
                <h2 className="text-lg font-medium text-neutral-900">Filters</h2>
                <button
                  type="button"
                  className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md text-neutral-600 hover:bg-neutral-50"
                  onClick={() => setIsFilterOpen(false)}
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>

              {/* Scrollable content area */}
              <div className="flex-1 overflow-y-auto">
                <div className="px-4 py-6">
                  <FilterSidebar
                    filters={filters}
                    filterOptions={filterOptions}
                    onChange={handleFiltersChange}
                    onClose={() => setIsFilterOpen(false)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex items-baseline justify-between border-b border-gray-200 pb-6">
          <div>
            <h1 className="text-xl font-bold capitalize">{category?.replace(/-/g, ' ')}</h1>
            {products.length > 0 && (
              <p className="mt-1 text-sm text-gray-500">
                {products.length} {products.length === 1 ? 'result' : 'results'}
              </p>
            )}
          </div>

          <div className="flex items-center">
            <button
              type="button"
              className="lg:hidden inline-flex items-center gap-x-2 rounded-md bg-white px-3.5 py-2 text-sm font-semibold border shadow-sm hover:bg-gray-50"
              onClick={() => setIsFilterOpen(true)}
            >
              <FunnelIcon className="-ml-0.5 h-5 w-5" aria-hidden="true"/>
              Filters
              {activeFiltersCount > 0 && (
                <span className="ml-1.5 rounded-full bg-accent px-1.5 py-0.5 text-xs font-medium text-primary">
                  {activeFiltersCount}
                </span>
              )}
            </button>
          </div>
        </div>

        <div className="flex gap-8 pt-6">
          {/* Desktop filter sidebar */}
          <div className="hidden lg:block">
            <FilterSidebar
              filters={filters}
              filterOptions={filterOptions}
              onChange={handleFiltersChange}
            />
          </div>

          {/* Product grid section */}
          <div className="flex-1">
            {error ? (
              <div className="text-center py-8">
                <p className="text-red-600">{error}</p>
                <button
                  onClick={() => {
                    setError(null);
                    loadProducts(true);
                  }}
                  className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Try Again
                </button>
              </div>
            ) : products.length > 0 ? (
              <>
               <ProductGrid products={products}/>

                <div ref={loader} className="w-full py-8 flex justify-center">
                  {isLoading && hasMore && (
                    <div className="animate-pulse text-gray-500">
                      Loading more products...
                    </div>
                  )}
                </div>
                
                {!hasMore && (
                  <div className="text-center text-gray-500 py-8">
                    No more products to load
                  </div>
                )}
              </>
            ) : !isLoading ? (
              <div className="text-center py-8">
                <p className="text-gray-500">No products found matching your criteria</p>
                {activeFiltersCount > 0 && (
                  <button
                    onClick={() => {
                      setFilters({
                        minPrice: filterOptions.price_range.min_price.toString(),
                        maxPrice: filterOptions.price_range.max_price.toString(),
                        sizes: [],
                        colors: [],
                        brands: []
                      });
                    }}
                    className="mt-4 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Clear all filters
                  </button>
                )}
              </div>
            ) : (
              <div className="py-8">
                <div className="max-w-7xl mx-auto grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                  {[...Array(6)].map((_, index) => (
                    <div
                      key={index}
                      className="animate-pulse bg-gray-200 rounded-lg h-64"
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';

export const NotFoundPage: React.FC = () => (
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 text-center">
    <h1 className="text-4xl font-bold">404</h1>
    <p className="mt-2 text-gray-600">Page not found</p>
    <Link to="/" className="mt-4 inline-block text-black underline">
      Return to homepage
    </Link>
  </div>
);
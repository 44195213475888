import React, { useEffect, useState } from 'react';
import { Carousel } from '../components/Carousel';
import { ProductGrid } from '../components/ProductGrid';
import { Product, CarouselSlide } from '../types';
import { api } from '../api';

export const HomePage: React.FC = () => {
  const [slides, setSlides] = useState<CarouselSlide[]>([]);
  const [trendingProducts, setTrendingProducts] = useState<Product[]>([]);
  const [newArrivals, setNewArrivals] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        setError(null);

        const [slidesResponse, trendingResponse, newArrivalsResponse] = await Promise.allSettled([
          api.getCarouselSlides(),
          api.getTrendingProducts(),
          api.getNewArrivals()
        ]);

        // Handle carousel slides
        if (slidesResponse.status === 'fulfilled') {
          setSlides(slidesResponse.value);
        } else {
          console.error('Failed to load carousel slides:', slidesResponse.reason);
          setSlides([]);
        }

        // Handle trending products
        if (trendingResponse.status === 'fulfilled') {
          setTrendingProducts(trendingResponse.value.results);
        } else {
          console.error('Failed to load trending products:', trendingResponse.reason);
          setTrendingProducts([]);
        }

        // Handle new arrivals
        if (newArrivalsResponse.status === 'fulfilled') {
          setNewArrivals(newArrivalsResponse.value.results);
        } else {
          console.error('Failed to load new arrivals:', newArrivalsResponse.reason);
          setNewArrivals([]);
        }

      } catch (err) {
        console.error('Error loading home page data:', err);
        setError('Failed to load content. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="animate-pulse">
          <div className="h-128 bg-neutral-200 mb-16"></div>
          <div className="h-8 bg-neutral-200 w-48 mb-8"></div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {[...Array(4)].map((_, i) => (
              <div key={i} className="h-64 bg-neutral-200"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 text-center">
        <div className="bg-red-50 p-4 rounded-lg">
          <p className="text-red-700">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="mt-4 bg-accent text-primary px-4 py-2 hover:bg-accent-light transition-colors"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="font-sans">
      <Carousel slides={slides} />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-8 sm:mt-16">
        <section>
          <h2 className="text-xl sm:text-2xl font-bold mb-6 sm:mb-8 text-primary">Trending Now</h2>
          <ProductGrid products={trendingProducts} />
        </section>

        <section className="mt-8 sm:mt-16">
          <h2 className="text-xl sm:text-2xl font-bold mb-6 sm:mb-8 text-primary">New Arrivals</h2>
          <ProductGrid products={newArrivals} />
        </section>
      </div>
    </div>
  );
};
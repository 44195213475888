import React from 'react';
import { Link } from 'react-router-dom';
import { XCircleIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';

export const PaymentCancelPage: React.FC = () => (
  <div className="min-h-screen bg-white flex items-center justify-center px-4">
    <div className="max-w-md w-full text-center">
      <XCircleIcon className="w-16 h-16 mx-auto text-gray-400" />
      <h1 className="mt-6 text-2xl font-medium text-gray-900">Payment Cancelled</h1>
      <p className="mt-2 text-base text-gray-500">
        Your payment was cancelled. Your cart items are still saved.
      </p>
      <div className="mt-8">
        <Link
          to="/cart"
          className="block w-full bg-black text-white py-3 px-4 rounded-lg hover:bg-gray-900 transition-colors font-medium flex items-center justify-center"
        >
          <ArrowLeftIcon className="w-5 h-5 mr-2" />
          Return to Cart
        </Link>
      </div>
    </div>
  </div>
);

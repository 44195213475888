import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Product } from '../types';

interface CartItem {
  id: number;
  product: Product;
  colorId: number;
  size?: string;
  quantity: number;
  colorImage?: string;
}

interface CartContextType {
cartItems: CartItem[];
  addToCart: (
    product: Product, 
    colorId: number, 
    size?: string, 
    quantity?: number,
    colorImage?: string
  ) => void;
  removeFromCart: (id: number) => void;
  updateQuantity: (id: number, quantity: number) => void;
  clearCart: () => void;
  cartCount: number;
  total: number;  // Added total
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [cartItems, setCartItems] = useState<CartItem[]>(() => {
    if (typeof window !== 'undefined') {
      const savedCart = localStorage.getItem('cart');
      return savedCart ? JSON.parse(savedCart) : [];
    }
    return [];  
  });

  const total = useMemo(() => {
    return cartItems.reduce((sum, item) => {
      return sum + (item.product.price * item.quantity);
    }, 0);
  }, [cartItems]);
  
  const [cartCount, setCartCount] = useState(() => {
    if (typeof window !== 'undefined') {
      const savedCart = localStorage.getItem('cart');
      const items = savedCart ? JSON.parse(savedCart) : [];
      return items.reduce((sum: number, item: CartItem) => sum + item.quantity, 0);
    }
    return 0;
  });

  // Update localStorage and cartCount whenever cartItems changes
  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('cart', JSON.stringify(cartItems));
      sessionStorage.setItem('cart', JSON.stringify(cartItems));
      const count = cartItems.reduce((sum, item) => sum + item.quantity, 0);
      setCartCount(count);
    }
  }, [cartItems]);

const addToCart = (
  product: Product, 
  colorId: number, 
  size?: string, 
  quantity: number = 1,
  colorImage?: string
) => {
  setCartItems(prevItems => {
    const existingItemIndex = prevItems.findIndex(
      item => 
        item.product.id === product.id && 
        item.colorId === colorId && 
        item.size === size
    );

    if (existingItemIndex > -1) {
      // Update existing item quantity
      const newItems = [...prevItems];
      newItems[existingItemIndex].quantity += quantity;
      return newItems;
    }

    // Add new item with specified quantity and color image
    return [...prevItems, {
      id: Date.now(),
      product,
      colorId,
      size,
      quantity,
      colorImage // Include the color-specific image
    }];
  });
};
  const removeFromCart = (id: number) => {
    setCartItems(prevItems => prevItems.filter(item => item.id !== id));
  };

  const updateQuantity = (id: number, quantity: number) => {
    if (quantity < 1) return;
    
    setCartItems(prevItems => 
      prevItems.map(item => 
        item.id === id ? { ...item, quantity } : item
      )
    );
  };

  const clearCart = () => {
    setCartItems([]);
    localStorage.removeItem('cart');
    sessionStorage.removeItem('cart');
  };

  return (
    <CartContext.Provider value={{
      cartItems,  // Note: this is what you'll access in your CartPage
      addToCart,
      removeFromCart,
      updateQuantity,
      clearCart,
      cartCount,
      total
    }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
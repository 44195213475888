import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import { Filters, FilterOptions } from '../types';

interface FilterSidebarProps {
  filters: Filters;
  filterOptions: FilterOptions;
  onChange: (filters: Filters) => void;
  onClose?: () => void;
}

export const FilterSidebar: React.FC<FilterSidebarProps> = ({
  filters,
  filterOptions,
  onChange,
  onClose
}) => {
  const selectItem = (key: keyof Filters, item: string) => {
    const newValue = filters[key] as string[];
    const newItems = newValue[0] === item ? [] : [item];
    onChange({ ...filters, [key]: newItems });
  };
  
  const [brandSearch, setBrandSearch] = React.useState('');

  // Initialize filters without any pre-filled values
  React.useEffect(() => {
    onChange({
      minPrice: '',
      maxPrice: '',
      sizes: [],
      colors: [],
      brands: []
    });
  }, []);

  return (
    <aside className="lg:w-72 w-full flex-shrink-0 font-sans">
      <div className="lg:sticky lg:top-20 lg:px-2">
        <div className="space-y-6">
          <Disclosure as="div" className="border-b border-neutral-200 pb-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full py-3 group">
                  <span className="font-medium text-sm tracking-wide uppercase text-neutral-900">
                    Price
                  </span>
                  <ChevronUpIcon
                    className={`${open ? 'transform rotate-180' : ''} w-4 h-4 text-neutral-500 group-hover:text-neutral-900 transition-colors`}
                  />
                </Disclosure.Button>
                
                <Disclosure.Panel className="pt-4 space-y-6">
                  <div className="relative flex items-center gap-3">
                    <div className="relative flex-1">
                      <div className="absolute left-3 top-1/2 -translate-y-1/2 text-neutral-400 text-sm">
                        $
                      </div>
                      <input
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        value={filters.minPrice}
                        onChange={(e) => {
                          const value = e.target.value.replace(/[^0-9]/g, '');
                          onChange({ ...filters, minPrice: value });
                        }}
                        placeholder="Min"
                        className="form-input w-full bg-neutral-50 border-neutral-200 rounded-none pl-7 pr-3 py-2.5 text-sm placeholder:text-neutral-400 focus:border-neutral-900 focus:ring-0 hover:border-neutral-400 transition-colors"
                      />
                    </div>
                    
                    <span className="text-neutral-300">―</span>
                    
                    <div className="relative flex-1">
                      <div className="absolute left-3 top-1/2 -translate-y-1/2 text-neutral-400 text-sm">
                        $
                      </div>
                      <input
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        value={filters.maxPrice}
                        onChange={(e) => {
                          const value = e.target.value.replace(/[^0-9]/g, '');
                          onChange({ ...filters, maxPrice: value });
                        }}
                        placeholder="Max"
                        className="form-input w-full bg-neutral-50 border-neutral-200 rounded-none pl-7 pr-3 py-2.5 text-sm placeholder:text-neutral-400 focus:border-neutral-900 focus:ring-0 hover:border-neutral-400 transition-colors"
                      />
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          {filterOptions.sizes.length > 0 && (
            <Disclosure as="div" className="border-b border-neutral-200 pb-2">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex justify-between w-full py-3 group">
                    <span className="font-medium text-sm tracking-wide uppercase text-neutral-900">
                      Size
                    </span>
                    <ChevronUpIcon
                      className={`${open ? 'transform rotate-180' : ''} w-4 h-4 text-neutral-500 group-hover:text-neutral-900 transition-colors`}
                    />
                  </Disclosure.Button>
                  
                  <Disclosure.Panel className="pt-4">
                    <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
                      {filterOptions.sizes.map((size) => (
                        <button
                          key={size}
                          onClick={() => selectItem('sizes', size)}
                          className={`px-4 py-2.5 text-sm border ${
                            filters.sizes.includes(size)
                              ? 'border-neutral-900 bg-neutral-900 text-white'
                              : 'border-neutral-200 hover:border-neutral-400 text-neutral-900'
                          } transition-all duration-200 hover:shadow-sm`}
                        >
                          {size}
                        </button>
                      ))}
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          )}

          {filterOptions.colors.length > 0 && (
            <Disclosure as="div" className="border-b border-neutral-200 pb-2">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex justify-between w-full py-3 group">
                    <span className="font-medium text-sm tracking-wide uppercase text-neutral-900">
                      Color
                    </span>
                    <ChevronUpIcon
                      className={`${open ? 'transform rotate-180' : ''} w-4 h-4 text-neutral-500 group-hover:text-neutral-900 transition-colors`}
                    />
                  </Disclosure.Button>
                  
                  <Disclosure.Panel className="pt-4">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
                      {filterOptions.colors.map((color) => (
                        <button
                          key={color.id}
                          onClick={() => selectItem('colors', color.name)}
                          className={`flex items-center px-3 py-2.5 border min-w-0 ${
                            filters.colors.includes(color.name)
                              ? 'border-neutral-900 bg-neutral-50'
                              : 'border-neutral-200 hover:border-neutral-400'
                          } transition-all duration-200 hover:shadow-sm`}
                        >
                          <div 
                            className={`w-5 h-5 rounded-full border ${
                              color.hex_code === '#FFFFFF' ? 'border-neutral-300' : 'border-neutral-200'
                            } shadow-sm`}
                            style={{ backgroundColor: color.hex_code }}
                          />
                          <span className={`ml-2.5 text-sm truncate ${
                            filters.colors.includes(color.name)
                              ? 'text-neutral-900'
                              : 'text-neutral-600 group-hover:text-neutral-900'
                          }`}>
                            {color.name}
                          </span>
                        </button>
                      ))}
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          )}

          {filterOptions.brands.length > 0 && (
            <Disclosure as="div" className="pb-2">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex justify-between w-full py-3 group">
                    <span className="font-medium text-sm tracking-wide uppercase text-neutral-900">
                      Brand
                    </span>
                    <ChevronUpIcon
                      className={`${open ? 'transform rotate-180' : ''} w-4 h-4 text-neutral-500 group-hover:text-neutral-900 transition-colors`}
                    />
                  </Disclosure.Button>
                  
                  <Disclosure.Panel className="pt-4">
                    <div className="relative">
                      {/*<div className="sticky top-0 bg-white mb-4">*/}
                        <div className="relative">
                          {/*<input*/}
                          {/*  type="text"*/}
                          {/*  placeholder="Search brands"*/}
                          {/*  value={brandSearch}*/}
                          {/*  onChange={(e) => setBrandSearch(e.target.value)}*/}
                          {/*  className="w-full form-input bg-neutral-50 border-neutral-200 rounded-none px-4 py-2.5 text-sm placeholder:text-neutral-400 focus:border-neutral-900 focus:ring-0 hover:border-neutral-400 transition-colors"*/}
                          {/*/>*/}
                          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                            <svg 
                              xmlns="http://www.w3.org/2000/svg" 
                              fill="none" 
                              viewBox="0 0 24 24" 
                              strokeWidth={1.5} 
                              stroke="currentColor" 
                              className="w-4 h-4 text-neutral-400"
                            >
                              <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" 
                              />
                            </svg>
                          </div>
                        </div>
                      {/*</div>*/}

                      <div className="overflow-y-auto">
                        <style>
                          {`
                            .brand-scroll::-webkit-scrollbar {
                              width: 4px;
                            }
                            .brand-scroll::-webkit-scrollbar-track {
                              background: transparent;
                            }
                            .brand-scroll::-webkit-scrollbar-thumb {
                              background-color: #E0E0E0;
                              border-radius: 20px;
                            }
                            .brand-scroll::-webkit-scrollbar-thumb:hover {
                              background-color: #BDBDBD;
                            }
                          `}
                        </style>
                        <div className="brand-scroll pr-2 space-y-0.5">
                          {filterOptions.brands
                            .filter(brand => 
                              brand.toLowerCase().includes(brandSearch.toLowerCase())
                            )
                            .map((brand) => (
                              <button
                                key={brand}
                                onClick={() => selectItem('brands', brand)}
                                className={`flex w-full px-3 py-2.5 text-left ${
                                  filters.brands.includes(brand)
                                    ? 'bg-neutral-900 text-white'
                                    : 'text-neutral-600 hover:bg-neutral-50 hover:text-neutral-900'
                                } transition-all duration-200`}
                              >
                                <span className="text-sm font-normal truncate">
                                  {brand}
                                </span>
                              </button>
                            ))}
                        </div>
                      </div>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          )}
        </div>
      </div>
    </aside>
  );
};
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Product, ProductColor, ProductSize } from '../types';
import { api } from '../api';
import { Heart, Share2 } from 'lucide-react';
import { ImageGallery } from '../components/ImageGallery';
import { useCart } from '../context/CartContext';
import { toast } from 'react-hot-toast';
import { Helmet } from 'react-helmet';

export const ProductPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [product, setProduct] = useState<Product | null>(null);
  const [selectedColor, setSelectedColor] = useState<ProductColor | null>(null);
  const [selectedSize, setSelectedSize] = useState<ProductSize | null>(null);
  const [quantity, setQuantity] = useState(1);
  const { addToCart } = useCart();
  const tracked = useRef(false);

  const loadProduct = useCallback(async () => {
    if (slug) {
      const data = await api.getProduct(slug);
      setProduct(data);
      if (data.colors.length > 0) {
        setSelectedColor(data.colors[0]);
        if (data.colors[0].sizes.length > 0) {
          setSelectedSize(data.colors[0].sizes[0]);
        }
      }
    }
  }, [slug]);

  useEffect(() => {
    loadProduct();
  }, [loadProduct]);


  useEffect(() => {
    if (selectedColor) {
      if (selectedColor.sizes.length > 0) {
        setSelectedSize(selectedColor.sizes[0]);
      } else {
        setSelectedSize(null);
      }
    }
  }, [selectedColor]);

  const getCurrentImages = useCallback(() => {
    if (!product) return [];
    return selectedColor?.images?.length ? selectedColor.images : product.images;
  }, [product, selectedColor]);

  const handleColorChange = (color: ProductColor) => {
    setSelectedColor(color);
  };

  const handleAddToCart = () => {
    if (!product || !selectedColor || !selectedSize) return;

    try {
      const colorImage = selectedColor.images.length > 0
        ? selectedColor.images[0].image
        : product.primary_image;

      const productUrls = JSON.parse(localStorage.getItem('productUrls') || '{}');
      productUrls[product.id] = `/product/${product.slug}`;
      localStorage.setItem('productUrls', JSON.stringify(productUrls));

      if (typeof window !== 'undefined' && window.fbq) {
        window.fbq('track', 'AddToCart', {
          content_ids: [product.id],
          content_name: product.name.trim(),
          content_type: 'product',
          content_category: product.category_name,
          brand: product.brand,
          contents: JSON.stringify([{
            id: product.id,
            quantity: quantity,
            item_price: parseFloat(product.price)
          }]),
          currency: 'USD',
          value: parseFloat(product.price) * quantity
        });
      }

      addToCart(
        product,
        selectedColor.color.id,
        selectedSize.size,
        quantity,
        colorImage
      );

      toast.success(
        quantity === 1
          ? 'Item added to bag'
          : `${quantity} items added to bag`,
        {
          duration: 2000,
          position: 'top-right',
          style: {
            background: '#333',
            color: '#fff',
            borderRadius: '8px',
          },
        }
      );
    } catch (error) {
      toast.error('Failed to add to bag. Please try again.', {
        duration: 2000,
        position: 'top-right',
      });
    }
  };

  const renderPriceSection = () => {
    if (!product) return null;

    const showComparison = product.compare_price &&
      product.compare_price !== product.price &&
      product.discount_percentage;

    return (
      <div className="mt-4 flex items-center space-x-3">
        <span className="text-2xl font-semibold">${product.price}</span>
        {showComparison && (
          <>
            <span className="text-lg text-gray-500 line-through">
              ${product.compare_price}
            </span>
            {product.discount_percentage !== null &&
              <span className="text-red-600 font-medium">
                {Math.round(product.discount_percentage)}% OFF
              </span>
            }
          </>
        )}
      </div>
    );
  };

  if (!product) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="animate-pulse">
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {product && (
  <Helmet>
    {/* Basic Tags */}
    <title>{`${product.name} | SHOPCIETY`}</title>
    
    {/* Required Open Graph Tags */}
    <meta property="og:title" content={product.name.trim()} />
    <meta property="og:description" content={product.description} />
    <meta property="og:url" content={`https://theshopciety.com/product/${product.slug}`} />
    <meta property="og:image" content={product.primary_image} />
    <meta property="og:type" content="product" />
    <meta property="og:price:amount" content={product.price} />
    <meta property="og:price:currency" content="USD" />
    <meta property="og:site_name" content="SHOPCIETY" />
    
    {/* Required Product Tags */}
    <meta property="product:brand" content={product.brand} />
    <meta property="product:availability" content={selectedSize?.stock ? 'in stock' : 'out of stock'} />
    <meta property="product:condition" content="new" />
    <meta property="product:retailer_item_id" content={product.sku} />
    
    {/* Optional but Recommended Product Tags */}
    <meta property="product:category" content={product.category_name} />
    <meta property="product:gender" content={product.category_name.toLowerCase().includes('women') ? 'Female' : 'Male'} />
    <meta property="product:item_group_id" content={`${product.brand.toLowerCase()}-${product.category_name.toLowerCase()}`} />

    {/* Additional Images */}
    {product.images
      .filter(img => !img.is_primary)
      .map((img, index) => (
        <meta 
          key={`image-${index}`}
          property="og:image" 
          content={img.image}
        />
    ))}

    {/* Sale Price Information if applicable */}
    {product.discount_percentage && product.compare_price && (
      <>
        <meta property="product:sale_price:amount" content={product.price} />
        <meta property="product:sale_price:currency" content="USD" />
        <meta property="og:price:amount" content={product.compare_price} />
      </>
    )}

    {/* Optional Custom Labels - Can be used for filtering/categorizing */}
    <meta property="product:custom_label_0" content={product.category_name} />
    {product.colors.map((color, index) => (
      <meta 
        key={`color-${index}`}
        property="product:custom_label_1" 
        content={color.color.name} 
      />
    ))}
  </Helmet>
)}
<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <ImageGallery images={getCurrentImages()} />
        
        <div className="flex flex-col">
          <div className="flex justify-between items-start">
            <div>
              <h2 className="text-sm font-medium text-gray-500">{product.brand}</h2>
              <h1 className="text-2xl font-bold mt-1">{product.name}</h1>
            </div>
          </div>

          {renderPriceSection()}

          <div className="mt-8">
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-medium">Color</h3>
              <span className="text-sm text-gray-500">
                {selectedColor?.color.name}
              </span>
            </div>
            <div className="grid grid-cols-8 gap-2 mt-2">
              {product.colors.map((color) => (
                <button
                  key={color.id}
                  onClick={() => handleColorChange(color)}
                  className={`w-8 h-8 rounded-full border transition-all ${
                    selectedColor?.id === color.id
                      ? 'ring-2 ring-black ring-offset-2'
                      : 'ring-1 ring-gray-200 hover:ring-gray-300'
                  }`}
                  style={{ backgroundColor: color.color.hex_code }}
                  title={color.color.name}
                />
              ))}
            </div>
          </div>

          <div className="mt-8">
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-medium">Size</h3>
              <button className="text-sm text-gray-500 underline">Size Guide</button>
            </div>
            <div className="grid grid-cols-4 gap-2 mt-2">
              {selectedColor?.sizes.map((size) => (
                <button
                  key={size.id}
                  onClick={() => setSelectedSize(size)}
                  disabled={size.stock === 0}
                  className={`px-4 py-3 text-sm border rounded-lg transition-colors ${
                    selectedSize?.id === size.id
                      ? 'border-black bg-black text-white'
                      : size.stock === 0
                      ? 'border-gray-200 text-gray-400 bg-gray-50 cursor-not-allowed'
                      : 'border-gray-200 hover:border-gray-300'
                  }`}
                >
                  {size.size}
                  {size.stock < 10 && size.stock > 0 && (
                    <span className="block text-xs mt-1 text-red-500">
                      Only {size.stock} left
                    </span>
                  )}
                </button>
              ))}
            </div>
          </div>

          <div className="mt-8">
            <h3 className="text-sm font-medium mb-2">Quantity</h3>
            <select
              value={quantity}
              onChange={(e) => setQuantity(Number(e.target.value))}
              className="block w-full rounded-lg border-gray-200 py-3 px-4 text-sm 
                focus:border-black focus:ring-black"
            >
              {[...Array(10)].map((_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
          </div>

          <button
            onClick={handleAddToCart}
            disabled={!selectedSize || !selectedColor}
            className="mt-8 w-full bg-black text-white py-4 px-6 rounded-lg
              hover:bg-gray-900 disabled:bg-gray-200 disabled:cursor-not-allowed 
              transition-all duration-200 transform active:scale-[0.98]
              disabled:transform-none"
          >
            <span className="flex items-center justify-center space-x-2">
              {selectedSize ? (
                <>
                  <span>Add to Bag</span>
                  {quantity > 1 && <span>({quantity})</span>}
                </>
              ) : (
                'Select Size'
              )}
            </span>
          </button>

          {selectedSize && (
            <p className="mt-2 text-sm text-gray-500 text-center">
              {selectedSize.stock > 10 
                ? 'In Stock'
                : selectedSize.stock > 0
                ? `Only ${selectedSize.stock} left in stock`
                : 'Out of Stock'}
            </p>
          )}

          <div className="mt-8 pt-8 border-t">
            <h3 className="font-medium mb-2">Description</h3>
            <p className="text-gray-600 text-sm whitespace-pre-line">
              {product.description}
            </p>
          </div>

          <div className="mt-8 pt-8 border-t">
            <h3 className="font-medium mb-4">Product Details</h3>
            <dl className="grid grid-cols-2 gap-4 text-sm">
              <div>
                <dt className="text-gray-500">Brand</dt>
                <dd className="mt-1">{product.brand}</dd>
              </div>
              <div>
                <dt className="text-gray-500">SKU</dt>
                <dd className="mt-1">{product.sku}</dd>
              </div>
              <div>
                <dt className="text-gray-500">Season</dt>
                <dd className="mt-1">
                  {product.season === 'SS' ? 'Spring/Summer' :
                   product.season === 'AW' ? 'Autumn/Winter' : 'All Year'}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { useCart } from '../context/CartContext';
import { 
  XMarkIcon, 
  PlusIcon, 
  MinusIcon, 
  ShoppingBagIcon, 
  ArrowLeftIcon,
  LockClosedIcon,
  TruckIcon,
  CreditCardIcon
} from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { api } from '../api';

interface PaymentDetails {
  currency: string;
  description: string;
  amount: number;
  first_name: string;
  last_name: string;
  email: string;
  shipping_address: ShippingAddress;
}

interface ShippingAddress {
  street: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
  phone: string;
}

interface FormErrors {
  first_name?: string;
  last_name?: string;
  email?: string;
  street?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  country?: string;
  phone?: string;
}

type CheckoutStep = 'cart' | 'shipping' | 'payment';

export const CartPage: React.FC = () => {
  const navigate = useNavigate();
  const { cartItems, removeFromCart, updateQuantity, total } = useCart();
  const [showShippingInfo, setShowShippingInfo] = useState(false);
  const [checkoutStep, setCheckoutStep] = useState<CheckoutStep>('cart');
  const [loading, setLoading] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState<string>('');
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  
  const [shippingDetails, setShippingDetails] = useState({
    first_name: '',
    last_name: '',
    email: '',
    street: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
    phone: ''
  });

  // Get total from localStorage when component mounts
  useEffect(() => {
    const savedTotal = localStorage.getItem('cartTotal');
    if (savedTotal) {
      const parsedTotal = parseFloat(savedTotal);
    }
  }, []);

  // Save total to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('cartTotal', total.toString());
  }, [total]);

  // Estimated delivery date (7 days from now)
  const deliveryDate = new Date();
  deliveryDate.setDate(deliveryDate.getDate() + 7);
  const formattedDeliveryDate = deliveryDate.toLocaleDateString('en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric'
  });

  const validateForm = () => {
    const errors: FormErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[\d\s-]{10,}$/;

    if (!shippingDetails.first_name) errors.first_name = 'First name is required';
    if (!shippingDetails.last_name) errors.last_name = 'Last name is required';
    if (!shippingDetails.email) errors.email = 'Email is required';
    else if (!emailRegex.test(shippingDetails.email)) errors.email = 'Invalid email format';
    if (!shippingDetails.street) errors.street = 'Street address is required';
    if (!shippingDetails.city) errors.city = 'City is required';
    if (!shippingDetails.state) errors.state = 'State is required';
    if (!shippingDetails.postal_code) errors.postal_code = 'Postal code is required';
    if (!shippingDetails.country) errors.country = 'Country is required';
    if (!shippingDetails.phone) errors.phone = 'Phone number is required';
    else if (!phoneRegex.test(shippingDetails.phone)) errors.phone = 'Invalid phone format';

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setShippingDetails(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear error when user starts typing
    if (formErrors[name as keyof FormErrors]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: undefined
      }));
    }
  };

  const handleQuantityChange = (itemId: number, currentQty: number, increment: boolean) => {
    const newQty = increment ? currentQty + 1 : currentQty - 1;
    if (newQty > 0 && newQty <= 10) {
      updateQuantity(itemId, newQty);
    }
  };

  const handleProductClick = (item: any) => {
    navigate(`/product/${item.product.slug}`);
  };

  const handleProceedToCheckout = async () => {
    if (checkoutStep === 'cart') {
      setCheckoutStep('shipping');
      return;
    }

    if (checkoutStep === 'shipping') {
      if (!validateForm()) {
        return;
      }
      setLoading(true);
      try {
        const paymentDetails: PaymentDetails = {
          amount: total,
          currency: 'USD',
          description: `Order of ${cartItems.length} items`,
          first_name: shippingDetails.first_name,
          last_name: shippingDetails.last_name,
          email: shippingDetails.email,
          shipping_address: {
            street: shippingDetails.street,
            city: shippingDetails.city,
            state: shippingDetails.state,
            postal_code: shippingDetails.postal_code,
            country: shippingDetails.country,
            phone: shippingDetails.phone
          }
        };

        const response = await api.postPaymentData(paymentDetails);

        if (response.data.redirect_url) {
          setPaymentUrl(response.data.redirect_url);
          setCheckoutStep('payment');
        }
      } catch (error) {
        console.error('Payment initiation failed:', error);
        // Show error toast here
      } finally {
        setLoading(false);
      }
    }
  };

  const handleBackToCart = () => {
    setCheckoutStep('cart');
    setPaymentUrl('');
  };

  const renderPaymentFrame = () => (
      <motion.div
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          className="fixed inset-0 bg-white z-50 overflow-y-auto"
      >
        <div className="min-h-full flex flex-col">
          {/* Header */}
          <div className="border-b border-gray-200 sticky top-0 bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-16 flex items-center justify-between">
              <button
                  onClick={() => setCheckoutStep('shipping')}
                  className="flex items-center text-gray-500 hover:text-black transition-colors"
              >
                <ArrowLeftIcon className="h-5 w-5 mr-2"/>
                <span className="hidden sm:inline">Back to Shipping</span>
                <span className="sm:hidden">Back</span>
              </button>
              <div className="flex items-center">
                <LockClosedIcon className="h-4 w-4 text-green-600 mr-2"/>
                <span className="text-sm text-gray-500">Secure Payment</span>
              </div>
              <div className="text-sm hidden sm:block">
                <span className="text-gray-500">Total:</span>
                <span className="ml-2 font-medium">${total.toFixed(2)}</span>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-1 relative">
            {paymentUrl ? (
                <div className="flex flex-col lg:flex-row h-full">
                  {/* Order Summary for Mobile - Shown at top */}
                  <div className="lg:hidden bg-white p-4 border-b border-gray-200">
                    <div className="space-y-4">
                      <div className="flex justify-between items-center">
                        <div>
                          <h3 className="text-lg font-medium">Order Summary</h3>
                          <p className="text-sm text-gray-500">{cartItems.length} items</p>
                        </div>
                        <div className="text-base font-medium">
                          ${total.toFixed(2)}
                        </div>
                      </div>
                      <div className="border-t border-gray-200 pt-4">
                        <h4 className="font-medium mb-2">Shipping to:</h4>
                        <address className="text-sm text-gray-500 not-italic">
                          {shippingDetails.first_name} {shippingDetails.last_name}<br/>
                          {shippingDetails.street}<br/>
                          {shippingDetails.city}, {shippingDetails.state} {shippingDetails.postal_code}<br/>
                          {shippingDetails.country}
                        </address>
                      </div>
                    </div>
                  </div>

                  {/* Payment iframe */}
                  <div className="flex-1 bg-gray-50">
                    <iframe
                        src={paymentUrl}
                        className="w-full h-[calc(100vh-13rem)] lg:h-full border-0"
                        frameBorder="0"
                        allow="payment"
                    />
                  </div>

                  {/* Order Summary for Desktop - Shown on side */}
                  <div className="hidden lg:block w-80 bg-white border-l border-gray-200 p-6">
                    <div className="space-y-6">
                      <div>
                        <h3 className="text-lg font-medium">Order Summary</h3>
                        <p className="mt-1 text-sm text-gray-500">
                          {cartItems.length} items
                        </p>
                      </div>
                      <div className="border-t border-gray-200 pt-4">
                        <div className="flex justify-between">
                          <span className="text-base font-medium">Total</span>
                          <span className="text-base font-medium">
                      ${total.toFixed(2)}
                    </span>
                        </div>
                      </div>
                      <div className="border-t border-gray-200 pt-4">
                        <h4 className="font-medium mb-2">Shipping to:</h4>
                        <address className="text-sm text-gray-500 not-italic">
                          {shippingDetails.first_name} {shippingDetails.last_name}<br/>
                          {shippingDetails.street}<br/>
                          {shippingDetails.city}, {shippingDetails.state} {shippingDetails.postal_code}<br/>
                          {shippingDetails.country}
                        </address>
                      </div>
                      <div className="border-t border-gray-200 pt-4">
                        <div className="flex items-center text-sm text-gray-500">
                          <LockClosedIcon className="h-4 w-4 mr-2"/>
                          Secure payment process
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            ) : (
                <div className="flex items-center justify-center h-full">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-black"></div>
                </div>
            )}
          </div>
        </div>
      </motion.div>);

  const CheckoutProgress = () => (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex items-center justify-center">
          <div className="flex items-center w-full max-w-2xl justify-between">
            <div className="flex flex-col items-center">
              <div className={`w-8 h-8 rounded-full flex items-center justify-center ${
                  checkoutStep === 'cart' ? 'bg-black text-white' : 'bg-accent text-primary'
              }`}>
                <ShoppingBagIcon className="w-4 h-4"/>
              </div>
              <span className="text-sm mt-1">Cart</span>
            </div>
            <div className="flex-1 h-0.5 bg-gray-200 mx-4">
              <div className={`h-full ${
                  checkoutStep !== 'cart' ? 'bg-green-500' : ''
              }`}/>
            </div>
            <div className="flex flex-col items-center">
              <div className={`w-8 h-8 rounded-full flex items-center justify-center ${
                  checkoutStep === 'shipping' ? 'bg-black text-white' :
                      checkoutStep === 'payment' ? 'bg-green-500 text-white' :
                          'bg-gray-200 text-gray-400'
              }`}>
                <TruckIcon className="w-4 h-4"/>
              </div>
              <span className="text-sm mt-1">Shipping</span>
            </div>
            <div className="flex-1 h-0.5 bg-gray-200 mx-4">
              <div className={`h-full ${
                  checkoutStep === 'payment' ? 'bg-green-500' : ''
              }`}/>
            </div>
            <div className="flex flex-col items-center">
              <div className={`w-8 h-8 rounded-full flex items-center justify-center ${
                  checkoutStep === 'payment' ? 'bg-black text-white' : 'bg-gray-200 text-gray-400'
              }`}>
                <CreditCardIcon className="w-4 h-4"/>
              </div>
              <span className="text-sm mt-1">Payment</span>
            </div>
          </div>
        </div>
      </div>
  );

  // Empty Cart View
  if (cartItems.length === 0) {
    return (
        <div className="min-h-screen bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
            <div className="text-center">
              <ShoppingBagIcon className="mx-auto h-12 w-12 text-gray-400"/>
              <h2 className="mt-6 text-2xl font-medium text-gray-900">Your shopping bag is empty</h2>
              <p className="mt-2 text-sm text-gray-500">
                Browse our collection to discover the latest arrivals
              </p>
              <button
                  onClick={() => navigate('/')}
                  className="mt-8 inline-flex items-center px-6 py-3 border border-black text-base font-medium text-black hover:bg-black hover:text-white transition-colors duration-200"
              >
                Continue Shopping
              </button>
            </div>
          </div>
        </div>
    );
  }

  return (
      <div className="min-h-screen bg-white">
        <CheckoutProgress/>

        {checkoutStep === 'cart' && (
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-8 pb-24">
              <h1 className="text-2xl font-semibold mb-8">Shopping Bag ({cartItems.length})</h1>

              <div className="lg:grid lg:grid-cols-12 lg:gap-x-12">
                {/* Cart Items */}
                <div className="lg:col-span-7">
                  <AnimatePresence>
                    {cartItems.map((item) => (
                        <motion.div
                            key={item.id}
                            layout
                            initial={{opacity: 0, y: 20}}
                            animate={{opacity: 1, y: 0}}
                            exit={{opacity: 0, y: -20}}
                            className="py-6 border-b border-gray-200 first:pt-0"
                        >
                          <div className="flex gap-3 sm:gap-6">
                            {/* Product Image */}
                            <div
                                className="relative aspect-[3/4] w-20 sm:w-32 flex-shrink-0 overflow-hidden rounded-lg bg-gray-100 cursor-pointer"
                                onClick={() => handleProductClick(item)}
                                role="link"
                                tabIndex={0}
                                onKeyPress={(e) => {
                                  if (e.key === 'Enter') handleProductClick(item);
                                }}
                            >
                              <img
                                  src={item.colorImage || item.product.primary_image || '/api/placeholder/300/400'}
                                  alt={`${item.product.name} - ${item.product.colors.find(c => c.color.id === item.colorId)?.color.name}`}
                                  className="h-full w-full object-cover object-center hover:opacity-75 transition-opacity"
                              />
                            </div>

                            {/* Product Details */}
                            <div className="flex-1 min-w-0 flex flex-col">
                        <div className="flex justify-between items-start gap-2">
                          <div className="min-w-0 flex-1">
                            <h3 className="text-sm sm:text-base font-medium text-gray-900 truncate">
                              {item.product.brand}
                            </h3>
                            <p className="text-sm text-gray-500 truncate">
                              {item.product.name}
                            </p>
                            <div className="mt-1 text-sm text-gray-500">
                              <p className="truncate">
                                Color: {item.product.colors.find(c => c.color.id === item.colorId)?.color.name}
                              </p>
                              {item.size && <p>Size: {item.size}</p>}
                            </div>
                          </div>
                          <div className="flex-shrink-0">
                            <p className="text-sm font-medium whitespace-nowrap">
                              ${(item.product.price * item.quantity).toFixed(2)}
                            </p>
                          </div>
                        </div>

                        {/* Actions */}
                        <div className="mt-4 flex flex-col sm:flex-row gap-3 sm:items-center sm:justify-between">
                          {/* Quantity Controls */}
                          <div className="inline-flex items-center border rounded-lg">
                            <button
                              onClick={() => handleQuantityChange(item.id, item.quantity, false)}
                              className="p-2 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                              disabled={item.quantity <= 1}
                            >
                              <MinusIcon className="h-4 w-4"/>
                            </button>
                            <span className="w-8 text-center text-sm">{item.quantity}</span>
                            <button
                              onClick={() => handleQuantityChange(item.id, item.quantity, true)}
                              className="p-2 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                              disabled={item.quantity >= 10}
                            >
                              <PlusIcon className="h-4 w-4"/>
                            </button>
                          </div>

                          {/* Action Buttons */}
                          <div className="flex items-center space-x-4 text-sm">
                            <button
                              onClick={() => removeFromCart(item.id)}
                              className="text-gray-500 hover:text-black transition-colors"
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </AnimatePresence>
            </div>

            {/* Summary */}
            <div className="mt-8 lg:mt-0 lg:col-span-5">
              <div className="sticky top-[88px]">
                <div className="bg-gray-50 rounded-lg p-4 sm:p-6">
                  <h2 className="text-lg font-medium text-gray-900">Order Summary</h2>
                  <div className="mt-6 space-y-4">
                    <div className="flex items-center justify-between text-sm">
                      <p className="text-gray-600">Subtotal</p>
                      <p className="font-medium">${total.toFixed(2)}</p>
                    </div>
                    <div className="flex items-center justify-between text-sm">
                      <p className="text-gray-600">Shipping</p>
                      <p className="font-medium text-green-600">Free</p>
                    </div>
                    <div className="pt-4 flex items-center justify-between border-t border-gray-200">
                      <p className="text-base font-medium">Total</p>
                      <p className="text-base font-medium">${total.toFixed(2)}</p>
                    </div>
                  </div>

                  {/* Estimated Delivery */}
                  <div className="mt-6 p-4 bg-white rounded-lg">
                    <p className="text-sm font-medium">Estimated Delivery</p>
                    <p className="mt-1 text-sm text-gray-500">{formattedDeliveryDate}</p>
                  </div>

                  {/* Shipping Info Accordion */}
                  <div className="mt-4">
                    <button
                      onClick={() => setShowShippingInfo(!showShippingInfo)}
                      className="text-sm text-gray-600 hover:text-black flex items-center"
                    >
                      <PlusIcon
                        className={`h-4 w-4 mr-1 transition-transform ${
                          showShippingInfo ? 'rotate-45' : ''
                        }`}
                      />
                      Shipping information
                    </button>
                    <AnimatePresence>
                      {showShippingInfo && (
                        <motion.div
                          initial={{ height: 0, opacity: 0 }}
                          animate={{ height: 'auto', opacity: 1 }}
                          exit={{ height: 0, opacity: 0 }}
                          className="mt-2 text-sm text-gray-500 overflow-hidden"
                        >
                          <ul className="space-y-2">
                            <li>• Free standard shipping on all orders</li>
                            <li>• Express delivery available (2-3 business days)</li>
                            <li>• Free returns within 30 days</li>
                          </ul>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>

                  {/* Checkout Button */}
                  <button
                    onClick={handleProceedToCheckout}
                    disabled={loading}
                    className="mt-6 w-full bg-black text-white py-4 rounded-lg hover:bg-gray-900 transition-colors font-medium disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
                  >
                    {loading ? (
                      <>
                        <span className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2"></span>
                        Processing...
                      </>
                    ) : (
                      <>
                        <TruckIcon className="h-4 w-4 mr-2" />
                        Continue to Shipping
                      </>
                    )}
                  </button>

                  {/* Payment Methods */}
                  <div className="mt-4 flex items-center justify-center">
                    <img
                      src="/api/placeholder/200/30?text=Payment+Methods"
                      alt="Payment methods"
                      className="h-5 sm:h-6"
                    />
                  </div>

                  {/* Secure Payment Notice */}
                  <div className="mt-4 flex items-center justify-center text-xs text-gray-500">
                    <LockClosedIcon className="h-3 w-3 mr-1" />
                    <span>Secure payment process</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

{checkoutStep === 'shipping' && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 py-8 pb-32 sm:pb-8"
          >
            <div className="space-y-6">
              <div className="bg-white rounded-lg shadow p-6">
                <h2 className="text-xl font-medium mb-6">Shipping Information</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      First Name
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      value={shippingDetails.first_name}
                      onChange={handleInputChange}
                      className={`w-full px-3 py-2 border rounded-lg ${
                        formErrors.first_name ? 'border-red-500' : 'border-gray-300'
                      }`}
                    />
                    {formErrors.first_name && (
                      <p className="mt-1 text-sm text-red-500">{formErrors.first_name}</p>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Last Name
                    </label>
                    <input
                      type="text"
                      name="last_name"
                      value={shippingDetails.last_name}
                      onChange={handleInputChange}
                      className={`w-full px-3 py-2 border rounded-lg ${
                        formErrors.last_name ? 'border-red-500' : 'border-gray-300'
                      }`}
                    />
                    {formErrors.last_name && (
                      <p className="mt-1 text-sm text-red-500">{formErrors.last_name}</p>
                    )}
                  </div>
                  <div className="md:col-span-2">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={shippingDetails.email}
                      onChange={handleInputChange}
                      className={`w-full px-3 py-2 border rounded-lg ${
                        formErrors.email ? 'border-red-500' : 'border-gray-300'
                      }`}
                    />
                    {formErrors.email && (
                      <p className="mt-1 text-sm text-red-500">{formErrors.email}</p>
                    )}
                  </div>
                  <div className="md:col-span-2">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Street Address
                    </label>
                    <input
                      type="text"
                      name="street"
                      value={shippingDetails.street}
                      onChange={handleInputChange}
                      className={`w-full px-3 py-2 border rounded-lg ${
                        formErrors.street ? 'border-red-500' : 'border-gray-300'
                      }`}
                    />
                    {formErrors.street && (
                      <p className="mt-1 text-sm text-red-500">{formErrors.street}</p>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      value={shippingDetails.city}
                      onChange={handleInputChange}
                      className={`w-full px-3 py-2 border rounded-lg ${
                        formErrors.city ? 'border-red-500' : 'border-gray-300'
                      }`}
                    />
                    {formErrors.city && (
                      <p className="mt-1 text-sm text-red-500">{formErrors.city}</p>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      State/Province
                    </label>
                    <input
                      type="text"
                      name="state"
                      value={shippingDetails.state}
                      onChange={handleInputChange}
                      className={`w-full px-3 py-2 border rounded-lg ${
                        formErrors.state ? 'border-red-500' : 'border-gray-300'
                      }`}
                    />
                    {formErrors.state && (
                      <p className="mt-1 text-sm text-red-500">{formErrors.state}</p>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Postal Code
                    </label>
                    <input
                      type="text"
                      name="postal_code"
                      value={shippingDetails.postal_code}
                      onChange={handleInputChange}
                      className={`w-full px-3 py-2 border rounded-lg ${
                        formErrors.postal_code ? 'border-red-500' : 'border-gray-300'
                      }`}
                    />
                    {formErrors.postal_code && (
                      <p className="mt-1 text-sm text-red-500">{formErrors.postal_code}</p>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Country
                    </label>
                    <input
                      type="text"
                      name="country"
                      value={shippingDetails.country}
                      onChange={handleInputChange}
                      className={`w-full px-3 py-2 border rounded-lg ${
                        formErrors.country ? 'border-red-500' : 'border-gray-300'
                      }`}
                    />
                    {formErrors.country && (
                      <p className="mt-1 text-sm text-red-500">{formErrors.country}</p>
                    )}
                  </div>
                  <div className="md:col-span-2">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      value={shippingDetails.phone}
                      onChange={handleInputChange}
                      className={`w-full px-3 py-2 border rounded-lg ${
                        formErrors.phone ? 'border-red-500' : 'border-gray-300'
                      }`}
                      placeholder="+1 (555) 555-5555"
                    />
                    {formErrors.phone && (
                      <p className="mt-1 text-sm text-red-500">{formErrors.phone}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Desktop button */}
            <div className="hidden sm:block mt-6">
              <button
                onClick={handleProceedToCheckout}
                disabled={loading}
                className="w-full bg-black text-white py-4 rounded-lg hover:bg-gray-900 transition-colors font-medium disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
              >
                {loading ? (
                  <>
                    <span className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2"></span>
                    Processing...
                  </>
                ) : (
                  <>
                    <CreditCardIcon className="h-4 w-4 mr-2" />
                    Continue to Payment
                  </>
                )}
              </button>
            </div>
          </motion.div>

          {/* Mobile button */}
          <div className="sm:hidden fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4 z-10">
            <div className="max-w-2xl mx-auto">
              <button
                onClick={handleProceedToCheckout}
                disabled={loading}
                className="w-full bg-black text-white py-4 rounded-lg hover:bg-gray-900 transition-colors font-medium disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
              >
                {loading ? (
                  <>
                    <span className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2"></span>
                    Processing...
                  </>
                ) : (
                  <>
                    <CreditCardIcon className="h-4 w-4 mr-2" />
                    Continue to Payment
                  </>
                )}
              </button>
              <div className="mt-4 flex items-center justify-center text-xs text-gray-500">
                <LockClosedIcon className="h-3 w-3 mr-1" />
                <span>Your information is secure and encrypted</span>
              </div>
            </div>
          </div>
        </>
)}
      {checkoutStep === 'payment' && renderPaymentFrame()}
    </div>
  );
};

export default CartPage;
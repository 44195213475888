import React from 'react';
import { Link } from 'react-router-dom';
import { ExclamationTriangleIcon, ArrowPathIcon } from '@heroicons/react/24/outline';

export const PaymentErrorPage: React.FC = () => (
  <div className="min-h-screen bg-white flex items-center justify-center px-4">
    <div className="max-w-md w-full text-center">
      <ExclamationTriangleIcon className="w-16 h-16 mx-auto text-red-500" />
      <h1 className="mt-6 text-2xl font-medium text-gray-900">Payment Failed</h1>
      <p className="mt-2 text-base text-gray-500">
        Something went wrong with your payment. Please try again.
      </p>
      <div className="mt-8 space-y-3">
        <Link
          to="/cart"
          className="block w-full bg-black text-white py-3 px-4 rounded-lg hover:bg-gray-900 transition-colors font-medium flex items-center justify-center"
        >
          <ArrowPathIcon className="w-5 h-5 mr-2" />
          Try Again
        </Link>
        <button
          onClick={() => window.location.href = 'mailto:support@yoursite.com'}
          className="block w-full bg-white text-black border border-black py-3 px-4 rounded-lg hover:bg-gray-50 transition-colors font-medium"
        >
          Contact Support
        </button>
      </div>
    </div>
  </div>
);

import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Search, ShoppingBag, User, Heart, Menu, X, ChevronDown } from 'lucide-react';
import { Category, PaginatedResponse } from '../../types';
import { useCart } from '../../context/CartContext';

import { api } from '../../api';
const SearchOverlay: React.FC<any> = ({
  searchQuery,
  setSearchQuery,
  setSearchOpen,
  handleSearch
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const categories = [
    { name: 'WOMENSWEAR', slug: 'womenswear' },
    { name: 'MENSWEAR', slug: 'menswear' },
    { name: 'KIDSWEAR', slug: 'kidswear' }
  ];

  useEffect(() => {
    const searchProducts = async () => {
      if (searchQuery.length < 2) {
        setSearchResults([]);
        return;
      }

      setLoading(true);
      try {
        const params = new URLSearchParams();
        params.append('name', searchQuery);
        if (selectedCategory) {
          params.append('category', selectedCategory);
        }

        const response = await fetch(`/api/search/?${params.toString()}`);
        if (!response.ok) {
          throw new Error('Search failed');
        }

        const data = await response.json();
        setSearchResults(data.results.slice(0, 6));
      } catch (error) {
        console.error('Search error:', error);
        setSearchResults([]);
      } finally {
        setLoading(false);
      }
    };

    const timeoutId = setTimeout(searchProducts, 300);
    return () => clearTimeout(timeoutId);
  }, [searchQuery, selectedCategory]);

  return (
      <>
        {/* Categories and Search Bar */}
<div className="fixed inset-x-0 top-0 bg-neutral-50 z-50 border-b border-neutral-200">
        <div className="max-w-[1440px] mx-auto px-4">
          {/* Categories */}
          <div className="grid grid-cols-3 sm:flex sm:justify-center sm:space-x-16 text-[13px] sm:text-[15px] font-normal tracking-wider">
            {categories.map((category) => (
              <button
                key={category.slug}
                onClick={() => setSelectedCategory(category.slug)}
                className={`py-3 sm:py-4 relative transition-colors duration-200 ${
                  selectedCategory === category.slug
                    ? 'text-primary font-medium'
                    : 'text-neutral-600 hover:text-primary'
                }`}
              >
                <span className="truncate">{category.name}</span>
                {selectedCategory === category.slug && (
                  <div className="absolute bottom-0 left-0 right-0 h-[2px] bg-accent"/>
                )}
              </button>
            ))}
          </div>

          {/* Search Bar */}
          <div className="relative flex items-center border-b border-neutral-200">
            <Search className="absolute left-0 w-5 h-5 text-neutral-500"/>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={`Search ${selectedCategory || ''}...`}
              className="w-full py-3 sm:py-4 pl-8 pr-8 text-sm sm:text-base outline-none bg-transparent placeholder-neutral-500"
              autoFocus
            />
            <button
              onClick={() => setSearchOpen(false)}
              className="absolute right-0 p-1 hover:bg-neutral-100 rounded-full"
            >
              <X className="w-5 h-5 text-neutral-500"/>
            </button>
          </div>
        </div>
      </div>

      {/* Results Container */}
      {searchQuery.length >= 2 && (
        <div className="fixed inset-x-0 top-[108px] sm:top-[132px] bottom-0 bg-neutral-50 z-40 overflow-auto">
          <div className="max-w-[1440px] mx-auto px-4 py-6">
            {loading ? (
              <div className="text-center py-4">
                <div className="animate-pulse text-neutral-500">Searching...</div>
              </div>
            ) : searchResults.length > 0 ? (
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 sm:gap-6">
                {searchResults.map((product) => (
                  <Link
                    key={product.id}
                    to={`/product/${product.slug}`}
                    className="group"
                    onClick={() => setSearchOpen(false)}
                  >
                    <div className="aspect-w-3 aspect-h-4 bg-neutral-100 mb-2 sm:mb-3">
                      <img
                        src={product.primary_image || '/api/placeholder/300/400'}
                        alt={product.name}
                        className="object-cover w-full h-full"
                      />
                    </div>
                    <h4 className="text-xs sm:text-sm font-medium text-neutral-900 group-hover:text-primary truncate">
                      {product.name}
                    </h4>
                    <p className="text-xs sm:text-sm text-neutral-500">${product.price}</p>
                  </Link>
                ))}
              </div>
            ) : (
              <div className="text-center py-8 text-neutral-500">
                No products found
              </div>
            )}
          </div>
        </div>
      )}

      {/* Overlay Background */}
      <div
        className="fixed inset-0 bg-primary/50 z-30"
        onClick={() => setSearchOpen(false)}
      />
    </>
  );
};

export const Header: React.FC = () => {
  const {cartCount} = useCart();
  const [categories, setCategories] = useState<Category[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [openSubcategories, setOpenSubcategories] = useState<{ [key: number]: boolean }>({});


  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const response = await api.getCategories();
        const categoryResponse = response as any;
        // Filter only main clothing categories
        const mainCategories = categoryResponse.results.filter((category: Category) =>
            ['Menswear', 'Womenswear', 'Kidswear'].includes(category.name)
        );
        setCategories(mainCategories);
      } catch (err) {
        console.error('Error fetching categories:', err);
        setCategories([]);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);
  useEffect(() => {
    setIsMenuOpen(false);
    setSearchOpen(false);
  }, [location]);

  const toggleSubcategory = (categoryId: number) => {
    setOpenSubcategories(prev => ({
      ...prev,
      [categoryId]: !prev[categoryId]
    }));
  };

  const mobileMenu = (
      isMenuOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
            <div className="fixed inset-y-0 left-0 w-full max-w-sm bg-white shadow-xl overflow-y-auto">
              <div className="p-4">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-xl font-bold">Menu</h2>
                  <button
                      onClick={() => setIsMenuOpen(false)}
                      className="p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
                      aria-label="Close menu"
                  >
                    <X className="w-6 h-6"/>
                  </button>
                </div>

                <nav className="space-y-6">
                  {categories.map((category) => (
                      <div key={category.id} className="space-y-4">
                        <div className="flex items-center justify-between">
                          <Link
                              to={`/category/${category.slug}`}
                              className="text-lg font-medium uppercase tracking-wide"
                              onClick={() => setIsMenuOpen(false)}
                          >
                            {category.name}
                          </Link>
                          {category.subcategories?.length > 0 && (
                              <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    toggleSubcategory(category.id);
                                  }}
                                  className="p-1 hover:bg-gray-100 rounded transition-colors duration-200"
                              >
                                <ChevronDown
                                    className={`w-5 h-5 transition-transform duration-200 ${
                                        openSubcategories[category.id] ? 'rotate-180' : ''
                                    }`}
                                />
                              </button>
                          )}
                        </div>
                        {category.subcategories?.length > 0 && (
                            <div
                                className={`pl-4 space-y-3 overflow-hidden transition-all duration-200 ${
                                    openSubcategories[category.id]
                                        ? 'max-h-96 opacity-100'
                                        : 'max-h-0 opacity-0'
                                }`}
                            >
                      {category.subcategories.map((subcat) => (
                        <Link
                          key={subcat.id}
                          to={`/category/${subcat.slug}`}
                          className="block text-gray-600 hover:text-black transition-colors duration-200"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          {subcat.name}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </nav>

          </div>
        </div>
      </div>
    )
  );
  
  const handleSearch = (e: React.FormEvent, category?: string) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      const searchParams = new URLSearchParams();
      searchParams.append('name', searchQuery.trim());
      if (category) {
        searchParams.append('category', category);
      }
      navigate(`/search?${searchParams.toString()}`);
      setSearchOpen(false);
    }
  };

  return (
      <header className="bg-neutral-50 relative z-50 font-sans">
        {/* Announcement Bar */}
        <div className="bg-primary text-neutral-50 py-2">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <div
                  className="animate-marquee whitespace-nowrap flex items-center justify-center text-sm tracking-wider">
                <span className="hidden sm:inline mx-4">Free Express Shipping</span>
                <span className="hidden sm:inline mx-4">•</span>
                <span className="sm:hidden mx-4">Free Shipping</span>
                <span className="mx-4">on Orders Over $200</span>
                <span className="hidden sm:inline mx-4">•</span>
                <span className="hidden sm:inline mx-4">Easy Returns</span>
                <span className="hidden sm:inline mx-4">•</span>
                <span className="hidden lg:inline mx-4">New Arrivals Every Week</span>
              </div>
            </div>
          </div>
        </div>

        {/* Main Header */}
        <div className="border-b border-neutral-200">
          <div className="max-w-7xl mx-auto">
            <nav className="relative px-4 sm:px-6 lg:px-8">
              <div className="h-16 flex items-center justify-between">
                {/* Mobile Menu Button */}
                <button
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                    className="lg:hidden p-2 hover:bg-neutral-100 rounded-md"
                    aria-label="Toggle menu"
                >
                  <Menu className="h-6 w-6 text-primary"/>
                </button>

                {/* Logo */}
                <Link
                    to="/"
                    className="text-l sm:text-2xl font-bold tracking-wider text-primary"
                >
                  <img
                      src="/logo.png" // The path to the image in the public folder
                      alt="Logo"
                      className="h-16 w-auto" // Adjust the size of the image
                  />
                </Link>

                {/* Desktop Navigation */}
                <div className="hidden lg:flex items-center justify-center flex-1 space-x-8 mx-8">
                  {categories.map((category) => (
                      <div
                          key={category.id}
                          className="relative group"
                          onMouseEnter={() => setActiveCategory(category.slug)}
                          onMouseLeave={() => setActiveCategory(null)}
                      >
                        <Link
                            to={`/category/${category.slug}`}
                            className="flex items-center space-x-1 py-6 text-neutral-600 hover:text-primary transition-colors duration-200"
                        >
                      <span className="text-sm font-medium uppercase tracking-wide">
                        {category.name}
                      </span>
                          {category.subcategories?.length > 0 && (
                              <ChevronDown
                                  className="w-4 h-4 transition-transform duration-200 group-hover:rotate-180"/>
                          )}
                        </Link>

                        {/* Mega Menu */}
                        {activeCategory === category.slug && category.subcategories?.length > 0 && (
                            <div
                                className="absolute top-full left-1/2 w-screen max-w-7xl bg-neutral-50 shadow-lg border-t border-neutral-200"
                                style={{
                                  transform: 'translateX(-50%)',
                                  left: '50%',
                                  marginLeft: '0',
                                  width: '100vw',
                                  maxWidth: '1280px',
                                }}
                                onMouseEnter={() => setActiveCategory(category.slug)}
                                onMouseLeave={() => setActiveCategory(null)}
                            >
                              <div className="grid grid-cols-12 gap-8 px-8 py-8 mx-auto max-w-7xl">
                                {/* Main Featured Section */}
                                <div className="col-span-5 pr-8 border-r border-neutral-200">
                                  <div className="aspect-w-4 aspect-h-5 bg-neutral-100 rounded-lg overflow-hidden">
                                    <img
                                        src={category.image || `/api/placeholder/400/500?text=${category.name}`}
                                        alt={`${category.name} featured`}
                                        className="object-cover w-full h-full transform hover:scale-105 transition-transform duration-500"
                                    />
                                  </div>
                                  <div className="mt-4">
                                    <h3 className="text-xl font-medium mb-2 text-primary">
                                      {category.name} Collection
                                    </h3>
                                    <p className="text-neutral-600 text-sm mb-4">
                                      Discover the latest trends in {category.name.toLowerCase()}
                                    </p>
                                    <Link
                                        to={`/category/${category.slug}`}
                                        className="inline-block bg-primary text-neutral-50 px-6 py-2 text-sm font-medium hover:bg-primary-light transition-colors"
                                    >
                                      Shop All {category.name}
                                    </Link>
                                  </div>
                                </div>

                                {/* Subcategories */}
                                <div className="col-span-5">
                                  <div className="grid grid-cols-2 gap-x-8 gap-y-6">
                                    {category.subcategories.map((subcat) => (
                                        <Link
                                            key={subcat.id}
                                            to={`/category/${subcat.slug}`}
                                            className="group"
                                        >
                                          <div
                                              className="text-lg font-medium text-neutral-900 group-hover:text-primary transition-colors">
                                            {subcat.name}
                                          </div>
                                          <div
                                              className="text-sm text-neutral-500 group-hover:text-neutral-700 transition-colors">
                                            Explore Collection →
                                          </div>
                                        </Link>
                                    ))}
                                  </div>
                                </div>

                                {/* Featured Section */}
                                <div className="col-span-2 space-y-6">
                                  <div className="border-b border-neutral-200 pb-4">
                                    <h3 className="text-sm font-medium text-neutral-900 uppercase tracking-wider mb-4">
                                      Featured
                                    </h3>
                                    <div className="space-y-3">
                                      <Link
                                          to={`/category/${category.slug}?filter=new-arrivals`}
                                          className="block text-base text-neutral-600 hover:text-primary transition-colors font-medium"
                                      >
                                        New Arrivals
                                      </Link>
                                      <Link
                                          to={`/category/${category.slug}?filter=trending`}
                                          className="block text-base text-neutral-600 hover:text-primary transition-colors font-medium"
                                      >
                                        Trending Now
                                      </Link>
                                      <Link
                                          to={`/category/${category.slug}?filter=bestsellers`}
                                          className="block text-base text-neutral-600 hover:text-primary transition-colors font-medium"
                                      >
                                        Bestsellers
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Bottom Banner */}
                              <div className="border-t border-neutral-200">
                                <div className="max-w-7xl mx-auto px-8 py-4 flex justify-between items-center text-sm">
                                  <div className="space-x-4">
                                    <span className="text-neutral-500">Free Shipping</span>
                                    <span className="text-neutral-500">•</span>
                                    <span className="text-neutral-500">Easy Returns</span>
                                    <span className="text-neutral-500">•</span>
                                    <span className="text-neutral-500">Express Delivery</span>
                                  </div>
                                  <Link
                                      to={`/category/${category.slug}/sale`}
                                      className="text-accent-dark font-medium hover:text-accent transition-colors"
                                  >
                                    Shop Sale →
                                  </Link>
                                </div>
                              </div>
                            </div>
                        )}
                      </div>
                  ))}
                </div>

                {/* Icons */}
                <div className="flex items-center space-x-4">
                  <button
                      onClick={() => setSearchOpen(true)}
                      className="p-2 hover:bg-neutral-100 rounded-full transition-colors"
                  >
                    <Search className="w-5 h-5 text-primary"/>
                  </button>
                  <Link
                      to="/cart"
                      className="p-2 hover:bg-neutral-100 rounded-full relative transition-colors"
                  >
                    <ShoppingBag className="w-5 h-5 text-primary"/>
                    {cartCount > 0 && (
                        <span
                            className="absolute -top-1 -right-1 bg-accent text-primary text-xs w-5 h-5 rounded-full flex items-center justify-center font-medium">
                      {cartCount}
                    </span>
                    )}
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>

        {searchOpen && (
            <SearchOverlay
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                setSearchOpen={setSearchOpen}
                handleSearch={handleSearch}
            />
        )}

        {mobileMenu}
      </header>);
};
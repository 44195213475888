import React from 'react';

export const Footer: React.FC = () => (
  <footer className="bg-gray-100 mt-16">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div>
          <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">
            Shop
            
          </h3>
          <ul className="mt-4 space-y-4">
            <li>
              <a href="#" className="text-gray-600 hover:text-gray-900">
                New Arrivals
              </a>
            </li>
            <li>
              <a href="#" className="text-gray-600 hover:text-gray-900">
                Trending Now
              </a>
            </li>
            <li>
              <a href="#" className="text-gray-600 hover:text-gray-900">
                Sales
              </a>
            </li>
          </ul>
        </div>
        
        <div>
          <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">
            Help
          </h3>
          <ul className="mt-4 space-y-4">
            <li>
              <a href="#" className="text-gray-600 hover:text-gray-900">
                Shipping
              </a>
            </li>
            <li>
              <a href="#" className="text-gray-600 hover:text-gray-900">
                Returns
              </a>
            </li>
            <li>
              <a href="#" className="text-gray-600 hover:text-gray-900">
                Contact
              </a>
            </li>
          </ul>
        </div>
        
        <div>
          <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">
            About
          </h3>
          <p className="mt-4 text-gray-600">
            Fashion Store is your destination for curated fashion pieces that make a statement.
          </p>
        </div>
      </div>
    </div>
  </footer>
);
import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { ProductImage } from '../types';
import { ImageModal } from './ImageModal';

interface ImageGalleryProps {
  images: ProductImage[];
}

export const ImageGallery: React.FC<{ images: ProductImage[] }> = ({ images }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  // Special layout for two images
  if (images.length === 2) {
    return (
      <>
        <div className="flex flex-col gap-1 max-w-[500px]">
          {/* Main image */}
          <div
            className="w-full aspect-[4/3] overflow-hidden bg-gray-100 rounded-lg cursor-pointer"
            onClick={() => {
              setSelectedImageIndex(0);
              setModalOpen(true);
            }}
          >
            <div className="relative group">
              <img
                src={images[0].image}
                alt={images[0].alt_text}
                className="w-full object-cover transition-transform duration-300 group-hover:scale-105"
                draggable={false}
              />
            </div>
          </div>

          {/* Secondary image */}
          <div
            className="w-1/2 h-24 overflow-hidden bg-gray-100 rounded-lg cursor-pointer"
            onClick={() => {
              setSelectedImageIndex(1);
              setModalOpen(true);
            }}
          >
            <div className="relative group">
              <img
                src={images[1].image}
                alt={images[1].alt_text}
                className="w-full object-cover transition-transform duration-300 group-hover:scale-105"
                draggable={false}
              />
            </div>
          </div>
        </div>

        <AnimatePresence>
          {modalOpen && (
            <ImageModal
              isOpen={modalOpen}
              onClose={() => setModalOpen(false)}
              images={images}
              initialIndex={selectedImageIndex}
            />
          )}
        </AnimatePresence>
      </>
    );
  }

  // Default layout for 3 or more images
  if (images.length >= 3) {
    return (
      <>
        <div className="flex flex-col gap-1 max-w-[500px]">
          {/* Main image */}
          <div
            className="w-full aspect-[4/3] overflow-hidden bg-gray-100 rounded-lg cursor-pointer"
            onClick={() => {
              setSelectedImageIndex(0);
              setModalOpen(true);
            }}
          >
            <div className="relative group">
              <img
                src={images[0].image}
                alt={images[0].alt_text}
                className="w-full object-cover transition-transform duration-300 group-hover:scale-105"
                draggable={false}
              />
            </div>
          </div>

          {/* Secondary images row */}
          <div className="flex gap-1 h-24">
            {images.slice(1, 3).map((image, index) => (
              <div
                key={image.id}
                className="flex-1 overflow-hidden bg-gray-100 rounded-lg cursor-pointer"
                onClick={() => {
                  setSelectedImageIndex(index + 1);
                  setModalOpen(true);
                }}
              >
                <div className="relative group">
                  <img
                    src={image.image}
                    alt={image.alt_text}
                    className="w-full object-cover transition-transform duration-300 group-hover:scale-105"
                    draggable={false}
                  />
                </div>
              </div>
            ))}
            
            {images.length > 3 && (
              <button
                className="flex-1 bg-gray-100 hover:bg-gray-200 transition-colors rounded-lg text-sm font-medium flex items-center justify-center"
                onClick={() => {
                  setSelectedImageIndex(3);
                  setModalOpen(true);
                }}
              >
                +{images.length - 3}
              </button>
            )}
          </div>
        </div>

        <AnimatePresence>
          {modalOpen && (
            <ImageModal
              isOpen={modalOpen}
              onClose={() => setModalOpen(false)}
              images={images}
              initialIndex={selectedImageIndex}
            />
          )}
        </AnimatePresence>
      </>
    );
  }
  // Single image layout
  if (images.length === 1) {
    return (
      <>
        <div
          className="max-w-[500px] aspect-[4/3] overflow-hidden bg-gray-100 rounded-lg cursor-pointer"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          <div className="relative group">
            <img
              src={images[0].image}
              alt={images[0].alt_text}
              className="w-full object-cover transition-transform duration-300 group-hover:scale-105"
              draggable={false}
            />
          </div>
        </div>

        <AnimatePresence>
          {modalOpen && (
            <ImageModal
              isOpen={modalOpen}
              onClose={() => setModalOpen(false)}
              images={images}
              initialIndex={0}
            />
          )}
        </AnimatePresence>
      </>
    );
  }

  // Fallback for no images
  return (
    <div className="max-w-[500px] aspect-[4/3] bg-gray-100 rounded-lg flex items-center justify-center">
      <span className="text-gray-400">No images available</span>
    </div>
  );
};
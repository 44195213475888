// src/api/index.ts
import axios, { AxiosError } from 'axios';
import { Product, Category, CarouselSlide, Color, ProductColor, ProductSize } from '../types';

const API_BASE_URL: string = process.env.REACT_APP_API_BASE_URL || 'https://theshopciety.com/api';

interface PaymentDetails{
  currency:string;
  description:string;
  amount:number;
  first_name:string;
  last_name:string;
  email:string;
}

interface PaginatedResponse<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

interface ProductFilters {
  category?: string;
  min_price?: number | string;
  max_price?: number | string;
  sizes?: string[];
  colors?: string[];
  ordering?: string;
  search?: string;
  brand?: string;
  page?: number;
  page_size?: number;
}

interface CategoryFilters {
  parent?: number | null;
  is_active?: boolean;
}

interface APIError {
  message: string;
  status: number;
  detail?: string;
}

class Api {
  private async get<T>(endpoint: string, params?: Record<string, any>): Promise<T> {
    try {
      const response = await axios.get<T>(`${API_BASE_URL}${endpoint}`, { params });
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  private handleError(error: unknown): APIError {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError<{ detail: string }>;
      return {
        message: axiosError.response?.data?.detail || 'An error occurred',
        status: axiosError.response?.status || 500,
        detail: axiosError.response?.data?.detail
      };
    }
    return {
      message: 'An unexpected error occurred',
      status: 500
    };
  }

  // Categories
// in api.ts
async getCategories(): Promise<PaginatedResponse<Category>> {
  const response = await axios.get(`${API_BASE_URL}/categories/`);
  return response.data;
}
  async getCategory(slug: string): Promise<Category> {
    return this.get<Category>(`/categories/${slug}/`);
  }

  async getCategorySubcategories(slug: string): Promise<Category[]> {
    return this.get<Category[]>(`/categories/${slug}/subcategories/`);
  }

  async getCategoryProducts(
    slug: string, 
    filters?: ProductFilters
  ): Promise<PaginatedResponse<Product>> {
    return this.get<PaginatedResponse<Product>>(
      `/categories/${slug}/products/`,
      filters
    );
  }
  async getCategoryChildren(slug: string): Promise<Category[]> {
    return this.get<Category[]>(`/categories/${slug}/children/`);
  }

  // Products
  async getProducts(filters?: ProductFilters): Promise<PaginatedResponse<Product>> {
    return this.get<PaginatedResponse<Product>>('/products/', filters);
  }

  async getProduct(slug: string): Promise<Product> {
    return this.get<Product>(`/products/${slug}/`);
  }

  async getFeaturedProducts(
    filters?: Omit<ProductFilters, 'featured'>
  ): Promise<PaginatedResponse<Product>> {
    return this.get<PaginatedResponse<Product>>('/products/featured/', filters);
  }

  async getNewArrivals(
    filters?: Omit<ProductFilters, 'is_new_arrival'>
  ): Promise<PaginatedResponse<Product>> {
    return this.get<PaginatedResponse<Product>>('/products/new_arrivals/', filters);
  }

  async getTrendingProducts(
    filters?: Omit<ProductFilters, 'is_trending'>
  ): Promise<PaginatedResponse<Product>> {
    return this.get<PaginatedResponse<Product>>('/products/trending/', filters);
  }

  async getProductsByBrand(
    brand: string,
    filters?: Omit<ProductFilters, 'brand'>
  ): Promise<PaginatedResponse<Product>> {
    return this.get<PaginatedResponse<Product>>('/products/by_brand/', {
      brand,
      ...filters
    });
  }


  async postPaymentData(data: PaymentDetails): Promise<any> {
    return axios.post(`${API_BASE_URL}/payments/`, data);
  }

  // Colors
  async getColors(): Promise<Color[]> {
    return this.get<Color[]>('/colors/');
  }

  async getColor(id: number): Promise<Color> {
    return this.get<Color>(`/colors/${id}/`);
  }

  async getColorProducts(
    colorId: number,
    filters?: Omit<ProductFilters, 'colors'>
  ): Promise<PaginatedResponse<Product>> {
    return this.get<PaginatedResponse<Product>>(
      `/colors/${colorId}/products/`,
      filters
    );
  }

  // Carousel
  async getCarouselSlides(): Promise<CarouselSlide[]> {
    return this.get<CarouselSlide[]>('/carousel/');
  }

  // Additional helper methods
  async getAvailableSizes(categorySlug?: string): Promise<string[]> {
    const products = await this.getProducts({ category: categorySlug });
    const sizes = new Set<string>();
    products.results.forEach(product => {
      product.colors.forEach((color:any) => {
        color.sizes.forEach((size:any) => {
          if (size.stock > 0) {
            sizes.add(size.size);
          }
        });
      });
    });
    return Array.from(sizes).sort();
  }

  async getAvailableColors(categorySlug?: string): Promise<Color[]> {
    const products = await this.getProducts({ category: categorySlug });
    const colors = new Map<number, Color>();
    products.results.forEach(product => {
      product.colors.forEach((color:any) => {
        colors.set(color.color.id, color.color);
      });
    });
    return Array.from(colors.values());
  }
}

export const api = new Api();
export default api;

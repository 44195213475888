import React from 'react';
import { Link } from 'react-router-dom';
import { CheckCircleIcon, ShoppingBagIcon, ArrowRightIcon } from '@heroicons/react/24/outline';

export const PaymentSuccessPage: React.FC = () => (
  <div className="min-h-screen bg-white flex items-center justify-center px-4">
    <div className="max-w-md w-full text-center">
      <CheckCircleIcon className="w-16 h-16 mx-auto text-green-500" />
      <h1 className="mt-6 text-2xl font-medium text-gray-900">Payment Successful</h1>
      <p className="mt-2 text-base text-gray-500">
        Thank you for your purchase. Your order has been confirmed.
      </p>
      <div className="mt-8 space-y-3">
        <Link
          to="/"
          className="block w-full bg-primary text-white border border-black py-3 px-4 rounded-lg hover:bg-gray-50 transition-colors font-medium flex items-center justify-center"
        >
          Continue Shopping
          <ArrowRightIcon className="w-5 h-5 ml-2" />
        </Link>
      </div>
    </div>
  </div>
);

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CartProvider } from './context/CartContext';
import { Layout } from './components/Layout/Layout';
import { HomePage } from './pages/HomePage';
import { CategoryPage } from './pages/CategoryPage';
import { ProductPage } from './pages/ProductPage';
import { Toaster } from 'react-hot-toast';

import { CartPage } from './pages/CartPage';
import { NotFoundPage } from './pages/NotFoundPage';

import {PaymentSuccessPage} from "./pages/PaymentSuccessPage";
import {PaymentCancelPage} from "./pages/PaymentCancelPage";
import {PaymentErrorPage} from "./pages/PaymentErrorPage";


const App: React.FC = () => {
  return (
    <Router>
      <CartProvider>
      <Toaster position="top-right" />
        <Layout>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/category/:category" element={<CategoryPage />} />
            <Route path="/product/:slug" element={<ProductPage />} />
            <Route path="/cart" element={<CartPage />} />
            <Route path="/payment/success" element={<PaymentSuccessPage />} />
            <Route path="/payment/cancel" element={<PaymentCancelPage />} />
            <Route path="/payment/error" element={<PaymentErrorPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Layout>
      </CartProvider>
    </Router>
  );
};

export default App;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ShoppingBag } from 'lucide-react';
import { Product } from '../types';
import { useCart } from '../context/CartContext';
import { toast } from 'react-hot-toast';

interface ProductCardProps {
  product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const [currentColorIndex, setCurrentColorIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const { addToCart } = useCart();

  const currentColor = product.colors[currentColorIndex];
  
  const displayImage = currentColor?.images.length 
    ? (isHovered && currentColor.images.length > 1 
        ? currentColor.images[1].image 
        : currentColor.images[0]?.image)
    : product.primary_image;

  const formatPrice = (price: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    }).format(price);
  };

  const handleAddToCart = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (currentColor) {
      addToCart(product, currentColor.color.id);
      toast.success('Added to cart');
    }
  };

  return (
    <div 
      className="group relative font-sans"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="relative aspect-[3/4] overflow-hidden bg-neutral-100">
        <Link to={`/product/${product.slug}`}>
          <img
            src={displayImage}
            alt={product.name}
            className="h-full w-full object-cover object-center transition-transform duration-700 group-hover:scale-105"
          />
        </Link>
        
        {/* Quick add section */}
        <div 
          className="absolute bottom-0 left-0 right-0 bg-neutral-50/90 backdrop-blur-sm p-4 transform translate-y-full transition-transform duration-300 ease-out group-hover:translate-y-0"
        >
          <div className="space-y-3">
            <div className="flex flex-wrap gap-2 justify-center">
              {product.colors.map((color, idx) => (
                <button
                  key={color.id}
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentColorIndex(idx);
                  }}
                  className={`w-6 h-6 rounded-full border transition-transform duration-200 hover:scale-110 ${
                    idx === currentColorIndex 
                      ? 'ring-2 ring-primary ring-offset-1' 
                      : 'hover:ring-1 hover:ring-neutral-300'
                  }`}
                  style={{ backgroundColor: color.color.hex_code }}
                  title={color.color.name}
                />
              ))}
            </div>
            <Link
              to={`/product/${product.slug}`}
              className="block w-full text-center bg-primary text-neutral-50 py-2.5 text-sm font-medium hover:bg-primary-light transition-colors"
            >
              Quick View
            </Link>
          </div>
        </div>

        {/* Sale tag */}
        {product.discount_percentage && (
          <div className="absolute top-4 left-4">
            <div className="bg-accent text-primary px-3 py-1 text-xs font-bold">
              -{Math.round(product.discount_percentage)}%
            </div>
          </div>
        )}
      </div>

      {/* Product info */}
      <div className="mt-4 space-y-1">
        <div>
          <h3 className="text-sm font-bold text-neutral-900">
            <Link to={`/product/${product.slug}`}>
              {product.brand}
            </Link>
          </h3>
          <p className="text-sm text-neutral-600 hover:text-neutral-900 transition-colors">
            <Link to={`/product/${product.slug}`}>
              {product.name}
            </Link>
          </p>
        </div>
        
        <div className="flex items-center gap-2">
          <span className="text-sm font-bold text-neutral-900">
            {formatPrice(product.price)}
          </span>
          {product.compare_price && product.compare_price !== product.price && (
            <span className="text-sm text-neutral-400 line-through">
              {formatPrice(product.compare_price)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;